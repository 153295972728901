import React, { Component } from 'react'
import { Link } from 'gatsby'
import facebook from '../../content/images/facebook-logo.png'
import qrcard from '../../content/images/QRcard.png'
import qrcard2 from '../../content/images/QRcard2.png'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ThemeContext from '../context/ThemeContext'

export default class Footer extends Component {
  static contextType = ThemeContext // eslint-disable-line
  render() {
    const theme = this.context
    return (
      <footer className="footer container">
        <div>
          {/* <a href="https://ko-fi.com/sesvtutorial" target="_blank" rel="noopener noreferrer">
            Ko-Fi
          </a>
          <a href="https://www.patreon.com/sesvtutorial" target="_blank" rel="noopener noreferrer">
            Patreon
          </a> */}
          <Link to="/tags" className="view-all">Tags</Link>
          <Link to="/">© 2021 All rights reserved</Link>
          {/* <a href="https://www.taniarascia.com/rss.xml" target="_blank" rel="noopener noreferrer">
            RSS
          </a> */}
        </div>
        <div>
          <MessengerCustomerChat
            pageId="102299125232381"
            appId="170547774582687"
          />
        </div>
        <div>
          <a href="https://drive.google.com/file/d/1bTmG1CP9h676iHw67maImt_G64re31Dd/view" title="Magic card" target="_blank">
            <img
              src={theme.dark ? qrcard : qrcard2}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Magic card"
            />
          </a>
          <a href="https://www.facebook.com/Apex-Software-Silicon-Valley-102299125232381" title="Apex Software Silicon Valley Facebook Page" target="_blank">
            <img
              src={facebook}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="Apex Software Silicon Valley Facebook Page"
            />
          </a>
        </div>
      </footer>
    )
  }
}


// <!-- Load Facebook SDK for JavaScript -->
//       <div id="fb-root"></div>
//       <script>
//         window.fbAsyncInit = function() {
//           FB.init({
//             xfbml            : true,
//             version          : 'v9.0'
//           });
//         };

//         (function(d, s, id) {
//         var js, fjs = d.getElementsByTagName(s)[0];
//         if (d.getElementById(id)) return;
//         js = d.createElement(s); js.id = id;
//         js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
//         fjs.parentNode.insertBefore(js, fjs);
//       }(document, 'script', 'facebook-jssdk'));</script>

//       <!-- Your Chat Plugin code -->
//       <div class="fb-customerchat"
//         attribution="setup_tool"
//         page_id="102299125232381">
//       </div>